import './App.css';
import {WilmaPlusCard} from "./Components/Card";
import {CreditBox} from "./Components/CreditBox";
import {StatusPreviewBlock} from "./Blocks/StatusPreviewBlock";

function App() {
  return (
      <>
          <div id={'attachment-background'}/>
          <div className="site-root">
              <WilmaPlusCard>
                  <h1>Wilma Plus Palvelintiedot</h1>
                  <StatusPreviewBlock style={{display: 'flex', flex: '1'}}/>
              </WilmaPlusCard>
              <CreditBox/>
          </div>
      </>
  );
}

export default App;
