import './StatusPreviewBlock.css'
import React, {useEffect, useState} from "react";
import Flexbox from 'flexbox-react';
import {LoadingWidget} from "../Components/LoadingWidget";
import {ErrorWidget} from "../Components/ErrorWidget";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './Tabs.css';

const ServerInformation = ({server}) => {
    return <div className={'server-info'}>
        <p>{server.name}</p>
        <span>{server.desc}</span>
    </div>
}

const MaintenanceInformation = ({maintenance}) => {
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")

    useEffect(() => {
        let maintenanceStartDate = new Date(Date.parse(maintenance.start));
        let maintenanceEndDate = new Date(Date.parse(maintenance.end));
        setStartDate(maintenanceStartDate.toLocaleString())
        if (maintenanceStartDate.getUTCDay() === maintenanceEndDate.getUTCDay()
            && maintenanceStartDate.getUTCFullYear() === maintenanceEndDate.getUTCFullYear()
            && maintenanceStartDate.getUTCMonth() === maintenanceEndDate.getUTCMonth()) {
            setEndDate(maintenanceEndDate.toLocaleTimeString())
        } else {
            setEndDate(maintenanceEndDate.toLocaleString())
        }
    }, [])

        return <div className={'server-info maintenance-info'}>
        <p>{maintenance.desc}</p>
        <span>{startDate} - {endDate}</span>
    </div>
}

const ServerItem = ({status}) => {
    return <Flexbox alignContent={"center"} justifyItems={'center'} alignItems={"center"} flexDirection={'column'} class={'animated-transitions flexible center-flex'} style={{width: '100%'}}>
        <img alt={status.available ? 'Palvelu toimii' : "Palvelu ei toimi"} src={process.env.PUBLIC_URL+`/icons/${status.available ? 'check' : 'xmark'}.svg`} className={'status-icon'} />
        <ServerInformation server={status}/>
    </Flexbox>
}

const MaintenanceItem = ({status}) => {
    return <Flexbox alignContent={"center"} justifyItems={'center'} alignItems={"center"} flexDirection={'column'} class={'animated-transitions flexible center-flex'} style={{width: '100%'}}>
        <img alt={status.done ? 'Huoltokatkos on ohitse' : "Huoltokatkos on menossa tai tulossa"} src={process.env.PUBLIC_URL+`/icons/${status.done ? 'check' : 'clock'}.svg`} className={'status-icon'} />
        <MaintenanceInformation maintenance={status}/>
    </Flexbox>
}

export const StatusPreviewBlock = ({id, style}) => {
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [statusInfo, setStatusInfo] = useState({servers: [], maintenances: []});

    async function getStatus() {
        let resp = await fetch(`https://status.wilmaplus.fi/api/v1/servers/`)
        if (resp.ok) {
            return await resp.json()
        }
        return {}
    }

    useEffect(() => {
        getStatus().then(statusInfo => {
            setLoading(false);
            setError(null);
            setStatusInfo(statusInfo)
        }).catch(err => {
            setLoading(false)
            setError({code: err.message.toString()})
        })
    },[])

    return <Flexbox style={style} alignContent={"center"} justifyContent={'center'} justifyItems={'center'} alignItems={"center"} flexDirection={"column"}>
        <LoadingWidget style={{display: isLoading ? 'flex' : 'none'}} text={'Ladataan...'}/>
        <Tabs style={{width: '100%', display: isLoading || error ? 'none' : 'block'}}>
            <TabList>
                <Tab>Palvelinten tila</Tab>
                <Tab>Huoltokatkokset</Tab>
            </TabList>
            <TabPanel>
                {statusInfo.servers.map(i => {return <ServerItem status={i}/>})}
            </TabPanel>
            <TabPanel style={{maxHeight: '250px', overflow: 'auto'}}>
                {statusInfo.maintenances.map(i => {return <MaintenanceItem status={i}/>})}
            </TabPanel>
        </Tabs>
        <ErrorWidget error={error} style={{
            display: error ? 'flex' : 'none'
        }}/>
    </Flexbox>
}